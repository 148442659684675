var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"dataTable elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.computedHeaders,"disable-sort":!true,"items":_vm.items,"mobile-breakpoint":"0","dense":true,"footer-props":{
      itemsPerPageOptions: [5, 10, 20, 30, 50, -1],
    },"show-expand":"","single-expand":"","item-key":"unique_id"},scopedSlots:_vm._u([(!false)?{key:"top",fn:function(){return [_c('v-row',{staticClass:"\n          d-flex\n          flex-column flex-md-row\n          align-center\n          justify-md-space-between\n        ",attrs:{"dense":""}},[_c('v-col',{staticClass:"\n            d-flex\n            flex-row\n            justify-end\n            align-center\n            mb-2\n            color-blue-text\n          ",attrs:{"cols":"12","md":"12"}},[_c('span',{staticClass:"ml-2"},[_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.handleRefresh()}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiRefresh)+" ")])],1)],1)])],1)]},proxy:true}:null,{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"d-flex"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{staticClass:"pa-4 mt-2 mb-2"},[_c('div',{staticStyle:{"border-bottom":"solid 1px #ef4370"}},[_c('span',{staticClass:"\n                    d-flex\n                    flex-row\n                    justify-sm-space-between\n                    align-center\n                  "},[_c('h2',[_vm._v(_vm._s(_vm.$t("multi_stop")))]),_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiMapMarkerPath))])],1),_c('br')]),_c('v-list',_vm._l((item.routes),function(stop,index){return _c('v-list-item-content',{key:stop.index},[(index === 0)?_c('span',{staticClass:"mb-6"},[_c('v-list-item-title',{staticClass:"d-flex flex-row align-center"},[_c('v-img',{attrs:{"src":require(("@/views/Multistop/assets/Stop" + index + ".png")),"max-width":"42"}}),_vm._v(" "+_vm._s(stop.source_address.split(",").splice(0, 2).join(","))+" ")],1)],1):_vm._e(),_c('v-list-item-title',{staticClass:"d-flex flex-row align-center"},[_c('v-img',{attrs:{"src":require(("@/views/Multistop/assets/Stop" + (index + 1) + ".png")),"max-width":"42"}}),_vm._v(" "+_vm._s(stop.destination_address .split(",") .splice(0, 2) .join(","))+" ")],1),_c('v-list-item-subtitle',{staticClass:"ml-13"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("multistop_total_stop"))+": "+_vm._s(_vm._f("currency")(stop.estimated_fare))+" ")])])],1)}),1)],1)],1)],1)],1)]}},{key:"header.source_address",fn:function(ref){
                        var header = ref.header;
return [_c('span',{staticClass:"d-flex flex-row align-center"},[_c('v-img',{staticStyle:{"max-width":"20px"},attrs:{"src":require('@/views/RunningTrips/assets/IconOriginMap.png')}}),_vm._v(" "+_vm._s(header.text)+" ")],1)]}},{key:"header.destination_address",fn:function(ref){
                        var header = ref.header;
return [_c('span',{staticClass:"d-flex flex-row align-center"},[_c('v-img',{staticStyle:{"max-width":"20px"},attrs:{"src":require('@/views/RunningTrips/assets/IconDestinationMap.png')}}),_vm._v(" "+_vm._s(header.text)+" ")],1)]}},{key:"header.stop_quantity",fn:function(ref){
                        var header = ref.header;
return [_c('span',{staticClass:"d-flex flex-row align-center"},[_c('v-img',{staticStyle:{"max-width":"20px"},attrs:{"src":require('@/views/Multistop/assets/StopMarkerSmall.png')}}),_vm._v(" "+_vm._s(header.text)+" ")],1)]}},{key:"item.start_time",fn:function(ref){
                        var item = ref.item;
return [_c('span',[_c('td',[_vm._v(" "+_vm._s(_vm._f("moment")(item.start_time,"DD-MM-YYYY h:mm a"))+" ")])])]}},{key:"item.unique_id",fn:function(ref){
                        var item = ref.item;
return [_c('span',[_c('td',[_c('a',{attrs:{"target":"_blank","href":("/multistop/" + (item._id))}},[_vm._v(" "+_vm._s(item.unique_id)+" ")])])])]}},{key:"item.trip_comment",fn:function(ref){
                        var item = ref.item;
return [_c('td',{staticClass:"text-start"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"truncate-text d-block"},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.trip_comment)+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.trip_comment)+" ")])],1)]}},{key:"item.stop_quantity",fn:function(ref){
                        var item = ref.item;
return [_c('span',[_c('td',[_c('v-img',{attrs:{"src":require(("@/views/Multistop/assets/Stop" + (item.routes.length) + ".png"))}})],1)])]}},{key:"item.quote_status",fn:function(ref){
                        var value = ref.value;
return [(value === 0)?_c('span',[_c('td',[_c('v-chip',{staticClass:"v-chip-light-bg warning--text",attrs:{"color":"warning"}},[_vm._v(" Cotizado ")])],1)]):_vm._e()]}},{key:"item.total",fn:function(ref){
                        var value = ref.value;
return [_c('span',[_c('td',[_vm._v(" "+_vm._s(_vm._f("currency")(value))+" ")])])]}},{key:"item.serviceType",fn:function(ref){
                        var value = ref.value;
return [(value)?_c('span',{staticClass:"d-flex flex-column justify-center align-center mt-2"},[_c('v-tooltip',{attrs:{"top":"","content-class":"bs-tooltip-top arrow","max-width":300,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"d-flex flex-column justify-center align-center"},'span',attrs,false),on),[_c('v-img',{attrs:{"src":("" + _vm.bucketUrl + (value.type_image_url)),"max-width":"60"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)}),_c('span',{staticClass:"fontUrbanist font-weight-bold"},[_vm._v(_vm._s(value.typename))])],1)]}}],null,true)},[_c('span',{staticClass:"d-flex flex-row justify-center align-center"},[_c('v-icon',{style:(!_vm.$vuetify.theme.isDark && 'color: #ffffff !important;'),attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccount)+" ")]),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(value.max_space)+" ")]),_c('span',{staticClass:"ml-2"},[_vm._v(" | ")]),_c('v-icon',{staticClass:"ml-2",style:(!_vm.$vuetify.theme.isDark && 'color: #ffffff !important;'),attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiBagSuitcase)+" ")]),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(value.baggages)+" ")])],1)])],1):_c('span')]}},{key:"item.actions",fn:function(ref){
                        var item = ref.item;
return [_c('v-menu',{attrs:{"rounded":".rounded-lg","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var attrs = ref.attrs;
                        var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.handleShowMultiStop(item._id)}}},[_vm._v(_vm._s(_vm.$t("View map")))])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.handleApproveMultiStop(item)}}},[_vm._v(_vm._s(_vm.$t("Approve")))])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.handleCancelMultiStop(item._id)}}},[_vm._v(_vm._s(_vm.$t("Delete")))])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.handleDownloadMultiStop(item._id)}}},[_vm._v(" "+_vm._s(_vm.$t("Download PDF"))+" ")])],1)],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }