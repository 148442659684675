<template>
  <div>
    <v-data-table
      :loading="loading"
      :headers="computedHeaders"
      :disable-sort="!true"
      :items="items"
      class="dataTable elevation-1"
      mobile-breakpoint="0"
      :dense="true"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 20, 30, 50, -1],
      }"
      show-expand
      single-expand
      item-key="unique_id"
    >
      <template v-slot:top v-if="!false">
        <v-row
          dense
          class="
            d-flex
            flex-column flex-md-row
            align-center
            justify-md-space-between
          "
        >
          <!-- <v-col
            md="4"
            cols="12"
            class="d-flex flex-row align-center mt-2"
            :class="$vuetify.breakpoint.mdAndUp ? 'ml-2' : 'pa-2'"
          >
            <v-text-field
              :label="$t('Search')"
              :prepend-inner-icon="icons.mdiMagnify"
              outlined
              dense
              v-model="search"
              clearable
              @keyup.esc="search = ''"
              :disabled="loading"
            >
            </v-text-field>
          </v-col> -->
          <v-col
            cols="12"
            md="12"
            class="
              d-flex
              flex-row
              justify-end
              align-center
              mb-2
              color-blue-text
            "
          >
            <span class="ml-2">
              <v-btn icon color="primary" @click="handleRefresh()">
                <v-icon>
                  {{ icons.mdiRefresh }}
                </v-icon>
              </v-btn>
            </span>
          </v-col>
        </v-row>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-row class="d-flex">
            <v-col cols="12" md="12">
              <v-card class="pa-4 mt-2 mb-2">
                <div style="border-bottom: solid 1px #ef4370">
                  <span
                    class="
                      d-flex
                      flex-row
                      justify-sm-space-between
                      align-center
                    "
                  >
                    <h2>{{ $t("multi_stop") }}</h2>
                    <v-icon>{{ icons.mdiMapMarkerPath }}</v-icon>
                  </span>
                  <br />
                </div>
                <v-list>
                  <v-list-item-content
                    v-for="(stop, index) in item.routes"
                    :key="stop.index"
                  >
                    <span v-if="index === 0" class="mb-6">
                      <v-list-item-title class="d-flex flex-row align-center">
                        <v-img
                          :src="
                            require(`@/views/Multistop/assets/Stop${index}.png`)
                          "
                          max-width="42"
                        ></v-img>
                        {{
                          stop.source_address.split(",").splice(0, 2).join(",")
                        }}
                        <!-- {{ stop.source_address }} -->
                      </v-list-item-title>
                    </span>
                    <v-list-item-title class="d-flex flex-row align-center">
                      <v-img
                        :src="
                          require(`@/views/Multistop/assets/Stop${
                            index + 1
                          }.png`)
                        "
                        max-width="42"
                      ></v-img>
                      {{
                        stop.destination_address
                          .split(",")
                          .splice(0, 2)
                          .join(",")
                      }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="ml-13">
                      <span>
                        {{ $t("multistop_total_stop") }}:
                        {{ stop.estimated_fare | currency }}
                      </span>
                      <!-- <span class="ml-1"> | </span>
                      <span class="ml-1">
                        {{$t('multistop_time_stop')}}:
                        {{ Math.ceil(stop.time) }}m
                      </span>
                      <span class="ml-1"> | </span>
                      <span class="ml-1">
                        {{$t('multistop_distance_stop')}}:
                        {{ stop.distance }}Km
                      </span> -->
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </td>
      </template>

      <template v-slot:header.source_address="{ header }">
        <span class="d-flex flex-row align-center">
          <v-img
            :src="require('@/views/RunningTrips/assets/IconOriginMap.png')"
            style="max-width: 20px"
          ></v-img>
          {{ header.text }}
        </span>
      </template>
      <template v-slot:header.destination_address="{ header }">
        <span class="d-flex flex-row align-center">
          <v-img
            :src="require('@/views/RunningTrips/assets/IconDestinationMap.png')"
            style="max-width: 20px"
          ></v-img>
          {{ header.text }}
        </span>
      </template>
      <template v-slot:header.stop_quantity="{ header }">
        <span class="d-flex flex-row align-center">
          <v-img
            :src="require('@/views/Multistop/assets/StopMarkerSmall.png')"
            style="max-width: 20px"
          ></v-img>
          {{ header.text }}
        </span>
      </template>

      <template #item.start_time="{ item }">
        <span>
          <td>
            {{ item.start_time | moment("DD-MM-YYYY h:mm a") }}
          </td>
        </span>
      </template>

      <template #item.unique_id="{ item }">
        <span>
          <td>
            <a target="_blank" :href="`/multistop/${item._id}`">
              {{ item.unique_id }}
            </a>
          </td>
        </span>
      </template>

      <template #item.trip_comment="{ item }">
        <td class="text-start">
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <span class="truncate-text d-block" v-bind="attrs" v-on="on">
                {{ item.trip_comment }}
              </span>
            </template>
            {{ item.trip_comment }}
          </v-tooltip>
        </td>
      </template>

      <template #item.stop_quantity="{ item }">
        <span>
          <td>
            <v-img
              :src="
                require(`@/views/Multistop/assets/Stop${item.routes.length}.png`)
              "
            ></v-img>
          </td>
        </span>
      </template>

      <template #item.quote_status="{ value }">
        <span v-if="value === 0">
          <td>
            <v-chip class="v-chip-light-bg warning--text" color="warning">
              Cotizado
            </v-chip>
          </td>
        </span>
      </template>

      <template #item.total="{ value }">
        <span>
          <td>
            {{ value | currency }}
          </td>
        </span>
      </template>

      <template #item.serviceType="{ value }">
        <span
          class="d-flex flex-column justify-center align-center mt-2"
          v-if="value"
        >
          <v-tooltip
            top
            content-class="bs-tooltip-top arrow"
            :max-width="300"
            bottom
          >
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
                class="d-flex flex-column justify-center align-center"
              >
                <v-img
                  :src="`${bucketUrl}${value.type_image_url}`"
                  max-width="60"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row> </template
                ></v-img>
                <span class="fontUrbanist font-weight-bold">{{
                  value.typename
                }}</span>
              </span>
            </template>
            <span class="d-flex flex-row justify-center align-center">
              <v-icon
                x-small
                :style="!$vuetify.theme.isDark && 'color: #ffffff !important;'"
              >
                {{ icons.mdiAccount }}
              </v-icon>
              <span class="ml-2">
                {{ value.max_space }}
              </span>
              <span class="ml-2"> | </span>
              <v-icon
                x-small
                class="ml-2"
                :style="!$vuetify.theme.isDark && 'color: #ffffff !important;'"
              >
                {{ icons.mdiBagSuitcase }}
              </v-icon>
              <span class="ml-2">
                {{ value.baggages }}
              </span>
            </span>
          </v-tooltip>
        </span>
        <span v-else>
          <!-- <v-progress-circular indeterminate /> -->
        </span>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-menu rounded=".rounded-lg" offset-y>
          <template v-slot:activator="{ attrs, on }">
            <!-- class="white--text ma-5" -->
            <v-btn v-bind="attrs" v-on="on" icon>
              <v-icon>
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item link>
              <v-list-item-title @click="handleShowMultiStop(item._id)">{{
                $t("View map")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item link>
              <v-list-item-title @click="handleApproveMultiStop(item)">{{
                $t("Approve")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item link>
              <v-list-item-title @click="handleCancelMultiStop(item._id)">{{
                $t("Delete")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item link>
              <v-list-item-title @click="handleDownloadMultiStop(item._id)">
                {{ $t("Download PDF") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import ModalApproveMultiStop from "./ModalApproveMultiStop.vue";

import {
  mdiDotsVertical,
  mdiRefresh,
  mdiMagnify,
  mdiMapMarkerPath,
  mdiAccount,
  mdiBagSuitcase,
} from "@mdi/js";
import axios from "@/plugins/axios";

export default {
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
    headers: {
      required: true,
    },
    items: {
      required: true,
    },
  },
  data() {
    return {
      icons: {
        mdiDotsVertical,
        mdiMagnify,
        mdiMapMarkerPath,
        mdiAccount,
        mdiBagSuitcase,
        mdiRefresh,
      },
      localLoading: false,
      search: "",
      bucketUrl: process.env.VUE_APP_IMAGE_BUCKET,
    };
  },
  computed: {
    ...mapState("auth", ["wallet", "overdraft"]),
    computedHeaders() {
      // let headers = this.headers;
      this.headers.map((head) => (head.text = this.$t(head.text)));
      return this.headers;
    },
  },
  methods: {
    ...mapActions(["setLoading"]),

    async handleCancelMultiStop(id) {
      const res = await this.$dialog.warning({
        text: this.$t("multistop_delete_modal"),
        actions: {
          false: "No",
          true: {
            text: this.$t("Yes"),
          },
        },
      });
      if (!!res) {
        try {
          this.setLoading(true);
          const { data } = await axios.delete(`/quotes/${id}`);
          if (data.success) {
            this.$dialog.notify.success("Cotización eliminada correctamente");
            this.$emit("cancelledMultiStopQuote");
          } else {
            throw new Error("Error deleting");
          }
        } catch (error) {
          this.$dialog.notify.error(error.message);
        } finally {
          this.setLoading(false);
        }
      }
    },
    handleShowMultiStop(id) {
      let route = this.$router.resolve({
        name: "multiStopShow",
        params: { id: id },
      });
      window.open(route.href, "_blank").focus();
    },
    async handleApproveMultiStop(item) {
      const params = {
        fullscreen: !this.$vuetify.breakpoint.mdAndUp,
        transition: "dialog-top-transition",
        item: item,
        persistent: false,
        width: "70%",
        hideCancel: true,
        // persistent: true,
        promoCodeSuccessMsg: this.$t("Promo code applied successfully"),
        promoCodeErrorMsg: this.$t("The promo code has expired or is invalid"),
        errorGettingEstimatedFareWithPromoCode: this.$t(
          "Error getting estimated fare with promo code"
        ),
        removeMsg: this.$t("Remove"),
        addMsg: this.$t("Add"),
        promoCodeMsg: this.$t("Promo Code"),
        addPromoCodeMsg: this.$t("Add Promo Code"),
        errorDateTimeMsg: this.$t(
          "You've selected an invalid time. Please create a new quote with a time that is at least 10 minutes from now."
        ),
      };
      const dialogInstance = await this.$dialog.showAndWait(
        ModalApproveMultiStop,
        params
      );
      if (dialogInstance) {
        this.$router.push({ name: "runningTrips" });
      } else {
        this.handleRefresh();
      }
    },
    handleRefresh() {
      this.$emit("refresh");
    },
    async handleDownloadMultiStop(id) {
      try {
        this.setLoading(true);
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_URL}/quotes/${id}`
        );
        if (data && data.success) {
          let totalStopDistance = 0;
          let totalStopTime = 0;
          let totalCostStop = 0;

          const routes = data.quote.routes.map((route, index) => ({
            stopNumber: index + 1,
            stopOrigin: route.source_address,
            stopDestination: route.destination_address,
            stopTime: `${Math.ceil(route.time)}m`,
            stopTimeOnly: Math.ceil(route.time),
            stopDistance: `${route.distance}Km`,
            costStop: this.$options.filters.currency(route.base_estimated),
            costStopOnly: route.base_estimated,
            costFee: this.$options.filters.currency(route.multi_stops_fee),
            costTotal: this.$options.filters.currency(route.estimated_fare),
            costTotalOnly: route.estimated_fare,
            stopDistanceOnly: route.distance,
          }));

          routes.forEach((item) => {
            totalStopDistance += item.stopDistanceOnly;
            totalStopTime += item.stopTimeOnly;
            totalCostStop += item.costTotalOnly;
          });

          totalStopDistance = totalStopDistance.toFixed(2);
          totalStopTime = Math.ceil(totalStopTime) + "m";

          this.$emit("updateQuote", {
            "Tipo de servicio": data.quote.serviceType,
            "Fecha y hora": this.$moment(new Date(data.quote.start_time))
              .locale("es")
              .format("DD/MM/YYYY HH:mm"),
            "Dirección de origen": routes?.[0].stopOrigin,
            "Dirección de destino": routes?.[routes.length - 1].stopDestination,
            Sobredemanda: Boolean(data.quote.is_surge_hours)
              ? `${data.quote.surge_multiplier}x`
              : "No",
            DISTANCIA: totalStopDistance + "Km",
            DURACIÓN: totalStopTime,
            TOTAL: this.$options.filters.currency(totalCostStop),
            routes: routes,
          });
        } else {
          throw new Error("Error in /quotes/id");
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      } finally {
        this.setLoading(false);
      }
    },
  },
};
</script>
<style lang="scss">
.dataTable {
  tbody tr:nth-of-type(even) {
    background-color: rgba(58, 53, 65, 0.04);
  }

  tbody tr:hover {
    background-color: rgba(0, 5, 34, 0.08) !important;
  }

  tbody tr {
    border-bottom: hidden !important;
  }
}
.custom-margin {
  margin-bottom: 14px;
}
</style>

<style lang="scss" scoped>
.truncate-text {
  max-width: 8rem !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
