import { render, staticRenderFns } from "./DataTable.vue?vue&type=template&id=548a6b7b&scoped=true&"
import script from "./DataTable.vue?vue&type=script&lang=js&"
export * from "./DataTable.vue?vue&type=script&lang=js&"
import style0 from "./DataTable.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./DataTable.vue?vue&type=style&index=1&id=548a6b7b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "548a6b7b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCard,VChip,VCol,VDataTable,VIcon,VImg,VList,VListItem,VListItemContent,VListItemSubtitle,VListItemTitle,VMenu,VProgressCircular,VRow,VTooltip})
