<template>
  <v-row class="app-invoice-preview">
    <v-col cols="12" ref="carInvoice">
      <v-card class="card-invoice">
        <img
          src="@/assets/favicon.png"
          class="image-back"
          :style="
            $vuetify.breakpoint.mdAndUp ? 'width: 400px;' : 'width: 150px;'
          "
        />
        <!-- Header -->
        <v-card-text class="py-9 px-8">
          <div class="invoice-header d-flex flex-wrap justify-space-between">
            <!-- Left Content -->
            <v-row>
              <v-col>
                <div class="">
                  <div class="d-flex align-center mb-6">
                    <img
                      src="@/assets/Logo_negro.svg"
                      alt="Ridery"
                      class="logo-image"
                    />
                  </div>
                  <p class="d-block font-weight-semibold text--primary">
                    Av Andres Bello (entre Las Palmas y La Salle)
                  </p>
                  <p class="d-block font-weight-semibold text--primary">
                    Local UNIAUTO nro s/n Urb Los Caobos Caracas
                  </p>
                  <p class="d-block font-weight-semibold text--primary">
                    Telf: 0412-6509977 02127822976
                  </p>
                </div>
              </v-col>
              <v-col>
                <!-- Right Content -->
                <div class="text-right">
                  <p>{{ $t("NON FISCAL") }}</p>
                  <span class="text--primary ml-2 font-weight-bold text-xl">
                    {{ $t("Service Quote") }}
                  </span>
                  <span>
                    <p class="mb-2 mt-5">
                      <span v-if="quote['Fecha y hora']"
                        >{{ $t("Date issued") }}:
                        {{ quote["Fecha y hora"] }}</span
                      >
                    </p>
                  </span>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="py-9 px-8">
          <div class="payment-details d-flex justify-space-between flex-wrap">
            <div class="">
              <p class="font-weight-semibold payment-details-header">
                {{ $t("Service Quote to") }}:
              </p>
              <p class="mb-1">{{ name }}</p>
            </div>
          </div>
        </v-card-text>

        <!-- Table viaje -->
        <v-simple-table
          class="purchased-items-table"
          v-if="!quote.is_multi_stop"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th style="text-align: start !important; min-width: 100px">
                  {{ $t("DATE AND TIME") }}
                </th>
                <th style="text-align: start !important">
                  {{ $t("SERVICIO") }}
                </th>
                <th style="text-align: start !important">
                  {{ $t("ORIGIN ADDRESS") }}
                </th>
                <th style="text-align: start !important">
                  {{
                    $t(
                      quote.routes && quote.routes.length
                        ? "PARADA FINAL"
                        : "DESTINATION ADDRESS"
                    )
                  }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{ quote["Fecha y hora"] }}
                </td>
                <td>
                  <span>
                    <span
                      class="
                        d-flex
                        flex-column
                        mt-2
                      "
                      v-if="quote['Tipo de servicio']"
                    >
                      <v-tooltip
                        top
                        content-class="bs-tooltip-top arrow"
                        :max-width="300"
                        bottom
                        :disabled="true"
                      >
                        <template #activator="{ on, attrs }">
                          <span
                            v-bind="attrs"
                            v-on="on"
                            class="
                              d-flex
                              flex-column
                              py-2
                              text-center
                            "
                          >
                            <img
                              :src="`${bucketUrl}${quote['Tipo de servicio'].type_image_url}`"
                              style="max-width: 60px"
                            />

                            <span class="fontUrbanist font-weight-bold">{{
                              quote["Tipo de servicio"].typename
                            }}</span>
                          </span>
                        </template>
                      </v-tooltip>
                    </span>
                  </span>
                </td>
                <td class="text-left">
                  {{ quote["Dirección de origen"] }}
                </td>
                <td class="text-left">
                  {{ quote["Dirección de destino"] }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-divider></v-divider>

        <!-- Table duracion -->
        <v-simple-table class="purchased-items-table mt-8 mb-6">
          <template v-slot:default>
            <thead>
              <tr>
                <th v-if="!quote.routes" style="text-align: start !important">
                  {{ $t("SURGE") }}
                </th>
                <th style="text-align: start !important">
                  {{ $t("DISTANCIA") }}
                </th>
                <th style="text-align: start !important">
                  {{ $t("TIEMPO ESTIMADO") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td v-if="!!!quote.routes" class="text-left">
                  {{
                    quote["Sobredemanda"] && quote["Sobredemanda"] !== "No"
                      ? "Sí"
                      : "No"
                  }}
                </td>
                <td class="text-left">
                  {{ quote["DISTANCIA"] }}
                </td>
                <td class="text-left">
                  {{ quote["DURACIÓN"] }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-divider class="mt-8"></v-divider>

        <template v-if="quote.routes">
          <v-simple-table
            class="purchased-items-table mb-8"
            v-if="quote.routes.length"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="text-align: start !important">
                    {{ $t("STOP") }}
                  </th>
                  <th style="text-align: start !important">
                    {{ $t("ORIGIN ADDRESS") }}
                  </th>
                  <th style="text-align: start !important">
                    {{ $t("DESTINATION ADDRESS") }}
                  </th>
                  <th style="text-align: start !important">
                    {{ $t("MULSTISTOP COST") }}
                  </th>
                  <th style="text-align: start !important">
                    {{ $t("RECARGO") }}
                  </th>
                  <th style="text-align: start !important">
                    {{ $t("TOTAL STOP") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="tr-avoid-page-break"
                  v-for="(route, index) in quote.routes"
                  :key="route.index"
                >
                  <td>
                    {{ index + 1 }}
                  </td>
                  <td class="text-left">
                    {{ route.stopOrigin }}
                  </td>
                  <td class="text-left">
                    {{ route.stopDestination }}
                  </td>
                  <td class="text-left">
                    {{ route.costStop }}
                  </td>
                  <td class="text-left">
                    {{ route.costFee }}
                  </td>
                  <td class="text-left">
                    {{ route.costTotal }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>

        <v-divider class="mt-8"></v-divider>

        <v-container class="mb-8">
          <v-row>
            <v-col>
              <div class="ml-4">
                <p class="mb-1">
                  <span class="font-weight-semibold"
                    >{{ $t("Ridery's Corporate team") }}:
                  </span>
                  <br />
                </p>
                <p>{{ $t("Thank you for choosing us") }}</p>
              </div>
            </v-col>
            <v-col>
              <v-card-text class="">
                <div class="payment-details d-flex justify-end flex-wrap">
                  <div
                    class="ml-8 d-flex justify-end flex-column align-end w-full"
                  >
                    <v-divider class="mt-4 mb-3 w-50"></v-divider>
                    <div class="d-flex justify-space-between w-50">
                      <span class="payment-details-header">
                        {{ $t("Total") }}:
                      </span>
                      <span class="font-weight-bold payment-details-header">
                        {{ quote["TOTAL"] }}
                      </span>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>

        <v-divider class="mb-8"></v-divider>
        <v-card-text>
          <div class="tr-avoid-page-break">
            <v-row class="ml-4">
              <p class="d-block font-weight-bold text--primary">
                {{
                  $t(
                    "Note: This quote represents a referential value of the trip"
                  )
                }}
              </p>
            </v-row>
            <v-row class="mt-4 ml-4">
              <p class="d-block">
                *{{ $t("the value of the trip is subject to change") }}
              </p>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mdiArrowLeft, mdiAccount, mdiBagSuitcase } from "@mdi/js";
import { mapState } from "vuex";

export default {
  props: {
    quote: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      icons: {
        mdiArrowLeft,
        mdiAccount,
        mdiBagSuitcase,
      },
      bucketUrl: process.env.VUE_APP_IMAGE_BUCKET,
    };
  },
  computed: {
    ...mapState("auth", ["name"]),
  },
};
</script>

<style scoped lang="scss">
@import "~@core/preset/preset/apps/invoice.scss";

.logo-image {
  width: 120px;
}

.image-back {
  opacity: 0.05;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
}
.card-invoice {
  position: relative;
}
.tr-avoid-page-break {
  page-break-inside: avoid;
}
.v-application {
  background: none !important;
}

.image-back {
  width: 400px !important;
}

.app-navigation-menu,
.v-app-bar,
.v-footer,
.product-buy-now,
.app-invoice-preview.row .col-12:last-of-type {
  display: none !important;
}

.app-invoice-preview.row .col-12:first-child {
  max-width: 100% !important;
  flex: 1 !important;
}

.v-main,
.app-content-container {
  padding: 0px !important;
}

.v-card {
  box-shadow: none !important;
}

.app-invoice-preview {
  .invoice-header,
  .payment-details,
  .invoice-total {
    &.d-flex {
      flex-direction: row !important;
    }
  }
}

// Create vuetify classes for html2pdf to work
.d-flex {
  display: flex !important;
}

.d-block {
  display: block !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.justify-space-between {
  justify-content: space-between !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-sm-row {
  @media (min-width: 576px) {
    flex-direction: row !important;
  }
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-sm-0 {
  @media (min-width: 576px) {
    margin-bottom: 0 !important;
  }
}

.text-right {
  text-align: right !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.text-xl {
  font-size: 1.5rem !important;
}

.text--primary {
  color: #000522 !important;
}

.mb-2 {
  margin-bottom: 8px !important;
}

.mt-4 {
  margin-top: 16px !important;
}

.mt-5 {
  margin-top: 20px !important;
}

.font-weight-semibold {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.py-6 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.py-9 {
  padding-top: 36px !important;
  padding-bottom: 36px !important;
}

.px-8 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.tr-avoid-page-break {
  page-break-inside: avoid !important;
}

.w-full {
  width: 100% !important;
}

.fontUrbanist {
  font-family: "Urbanist" !important;
}

.mb-1 {
  margin-bottom: 4px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-2 {
  margin-top: 8px !important;
}

.justify-center {
  justify-content: center !important;
}

.align-center {
  align-items: center !important;
}

.ml-2 {
  margin-left: 8px !important;
}

.pe-16 {
  padding-right: 16px !important;
}

.mb-3 {
  margin-bottom: 12px !important;
}

.mt-8 {
  margin-top: 32px !important;
}

.mb-6 {
  margin-bottom: 24px !important;
}

.border-bottom-thin {
  border-bottom: 1px solid rgba(0, 0, 0, 0.14) !important;
}

.py-2 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.text-center {
  text-align: center !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.ml-8 {
  margin-left: 32px !important;
}

.align-end {
  align-items: flex-end !important;
}

.w-50 {
  width: 50% !important;
}

.my-8 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.text-left {
  text-align: left !important;
}
</style>
